import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import {
  ItemList,
  SubList,
  SubListTitle,
  SubListTitleLeft,
  SubListTitleRight,
  StyledLink,
  SubLink,
  StyledExternalLink,
  SubItemText,
  Item,
  LockedSubLink,
  LockedLink,
  MenuIconOuter,
  SpinnerOuter,
  ItemRight,
  ItemRightOuter
} from "./styles"
import { NavIcons, StatusIcons, LoadingSpinner, Badge } from "@cleversteam/se-hanover-components"
import Arrow from "@localComponents/arrow"
import Separator from "@localComponents/separator"
import { withChecklist } from "@hoc/checklist"
import { camelize, pluralize } from "@helpers/stringHelpers"
import { getTheme, getBrand } from "@helpers/siteHelpers"
import UserContext from "@context/UserContext"
import MenuContext from "@context/MenuContext"
import "react-tippy/dist/tippy.css"
import { Tooltip } from "react-tippy"

const getNavIcon = (item) => {
  const iconName = camelize(item.system_name, true)
  return NavIcons[iconName + "Icon"]
}

const getStatusIcon = (item) => {
  if (item.status === undefined) return
  const iconName = camelize(item.status, true)

  return StatusIcons[iconName + "Icon"]
}

const Items = (props) => {
  const { checklist, profileStatus, currentPageInProfileChecklist, showCampOrResortButton } = props
  const [showSubList, setShowSubList] = useState(
    currentPageInProfileChecklist || !["complete", "locked"].includes(profileStatus)
  )
  const [profileBadge, setProfileBadge] = useState(0)
  const [applicationSwitcherOpen, setApplicationSwitcherOpen] = useState(false)
  const theme = getTheme()
  const ground = pluralize(getBrand().employerType)
  const GroundIcon = NavIcons[`${ground}Icon`]

  const { menu, setMenu } = useContext(UserContext)
  const { currentApplication, helpSupportUrl, perksEnabled, userApplications, liveChat } = menu
  const { logoutUser, toggleMobileApplicationList, toggleMenu, changeApplication } = useContext(MenuContext)

  useEffect(() => {
    setShowSubList(currentPageInProfileChecklist || !["complete", "locked"].includes(profileStatus))
  }, [profileStatus, currentPageInProfileChecklist])

  const innerProfileItems = checklist.filter((item) => item.profile_position === "inside")
  useEffect(() => {
    const incomplete = innerProfileItems.filter((item) =>
      ["incomplete", "started", "not_started"].includes(item.status)
    )
    setProfileBadge(incomplete.length)
  }, [innerProfileItems])

  const toggleSubList = () => setShowSubList(!showSubList)

  const toggleApplicationSwitcher = (event) => {
    event.preventDefault()
    setApplicationSwitcherOpen(!applicationSwitcherOpen)
  }

  const openLiveChat = () => {
    // we call window function for missive
    if (liveChat.integration_type === "missive") {
      if (window.MissiveChat) window.MissiveChat.open()
      return
    }

    setMenu({ ...menu, liveChat: { ...menu.liveChat, show: true } })
  }

  return (
    <>
      {checklist.length === 0 && (
        <SpinnerOuter>
          <LoadingSpinner variant="default" />
        </SpinnerOuter>
      )}

      {checklist.length > 0 && (
        <ItemList>
          <Item>
            <StyledLink exact to="/" activeClassName="active" onClick={toggleMenu}>
              <MenuIconOuter>
                <NavIcons.HomeIcon size="1x" color={theme.colors.body} />
                <span style={{ paddingTop: "1px" }}>Home</span>
              </MenuIconOuter>
            </StyledLink>
          </Item>
          {showCampOrResortButton && (
            <Item>
              <StyledLink
                exact
                to={`/employers?type=${ground.toLowerCase()}`}
                activeClassName="active"
                onClick={toggleMenu}
              >
                <MenuIconOuter>
                  <GroundIcon size="1x" color={theme.colors.body} />
                  <span style={{ paddingTop: "1px" }}>{ground}</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}

          <Separator mobileOnly={false} shadow halfMargin />

          <Item>
            <SubListTitle onClick={toggleSubList} className={currentPageInProfileChecklist ? "active" : ""}>
              <SubListTitleLeft>
                <MenuIconOuter>
                  <NavIcons.ProfileIcon size="1x" color={theme.colors.body} />
                  <span>Profile</span>
                </MenuIconOuter>
              </SubListTitleLeft>

              <SubListTitleRight>
                {profileBadge > 0 && (
                  <Badge fontWeight="600" color={theme.colors.primaryContrastedDarker}>
                    {profileBadge}
                  </Badge>
                )}
                <ItemRightOuter>
                  <Arrow direction={showSubList ? "up" : "down"} menu />
                </ItemRightOuter>
              </SubListTitleRight>
            </SubListTitle>
            <SubList showSubList={showSubList}>
              {innerProfileItems.length > 0 &&
                innerProfileItems.map((item, index) => {
                  const ItemStatusIcon = getStatusIcon(item)

                  return (
                    <Item key={`${item.system_name}_${index}`}>
                      {!!item.locked ? (
                        <Tooltip title="This segment has been locked." delay="500">
                          <LockedSubLink>
                            {ItemStatusIcon && <ItemStatusIcon color={theme.colors.body} size="sm"></ItemStatusIcon>}
                            <SubItemText>{item.name}</SubItemText>
                          </LockedSubLink>
                        </Tooltip>
                      ) : (
                        <SubLink to={item.path} activeClassName="active" onClick={toggleMenu}>
                          {ItemStatusIcon && (
                            <ItemStatusIcon color={theme.colors.body} status={item.status} size="sm"></ItemStatusIcon>
                          )}
                          <SubItemText>{item.name}</SubItemText>
                        </SubLink>
                      )}
                    </Item>
                  )
                })}
            </SubList>
          </Item>

          {checklist
            .filter((item) => item.profile_position === "outside")
            .map((item, index, arr) => {
              const ItemNavIcon = getNavIcon(item)
              return (
                <Item key={`${item.system_name}_${index}`} extraMargin={arr.length - 1 === index}>
                  {!!item.locked ? (
                    <Tooltip title="This segment has been locked.">
                      <LockedLink hasicon="true">
                        <MenuIconOuter>
                          {ItemNavIcon && <ItemNavIcon size="1x" color={theme.colors.body} />}
                          <span style={{ paddingTop: "1px" }}>{item.name}</span>
                        </MenuIconOuter>
                        <ItemRight>
                          <StatusIcons.LockedIcon
                            color={theme.colors.body}
                            status="locked"
                            size="1x"
                          ></StatusIcons.LockedIcon>
                          <ItemRightOuter>&nbsp;</ItemRightOuter>
                        </ItemRight>
                      </LockedLink>
                    </Tooltip>
                  ) : (
                    <StyledLink to={item.path} activeClassName="active" hasicon="true" onClick={toggleMenu}>
                      <MenuIconOuter>
                        {ItemNavIcon && <ItemNavIcon size="1x" color={theme.colors.body} />}
                        <span style={{ paddingTop: "1px" }}>{item.name}</span>
                      </MenuIconOuter>
                      <ItemRight>
                        {item.badge_count > 0 && (
                          <Badge fontWeight="600" color={theme.colors.primaryContrastedDarker}>
                            {item.badge_count}
                          </Badge>
                        )}
                        <ItemRightOuter>&nbsp;</ItemRightOuter>
                      </ItemRight>
                    </StyledLink>
                  )}
                </Item>
              )
            })}

          <Separator mobileOnly={false} shadow halfMargin style={{ marginBottom: "1rem" }} />

          {helpSupportUrl && (
            <Item>
              <StyledExternalLink href={helpSupportUrl} target="_blank" info="1" onClick={toggleMenu}>
                <MenuIconOuter>
                  <NavIcons.HelpIcon size="1x" color={theme.colors.body} />
                  <span>Help &amp; Information</span>
                </MenuIconOuter>
              </StyledExternalLink>
            </Item>
          )}

          {liveChat.integration_type && (
            <Item>
              <StyledLink to="#" activeClassName="none" info="1" onClick={openLiveChat}>
                <MenuIconOuter>
                  <NavIcons.LiveChatIcon size="1x" color={theme.colors.body} />
                  <span>Live Chat</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}

          {userApplications.filter((app) => app.programme_name !== currentApplication.programme_name).length > 0 && (
            <Item desktopOnly={true} hidden>
              <StyledLink to="#" activeClassName="none" info="1">
                <MenuIconOuter>
                  <NavIcons.SwitchIcon size="1x" color={theme.colors.body} />
                  <span onClick={toggleApplicationSwitcher}>Switch Application</span>
                </MenuIconOuter>
              </StyledLink>
              {applicationSwitcherOpen && (
                <>
                  {userApplications
                    .filter((app) => app.programme_name !== currentApplication.programme_name)
                    .map((application, index) => {
                      return (
                        <StyledLink
                          info
                          indent
                          activeClassName="false"
                          to="#"
                          data-id={application.application_container_id}
                          data-brand={application.brand}
                          onClick={changeApplication}
                        >
                          {application.programme_name}
                        </StyledLink>
                      )
                    })}
                </>
              )}
            </Item>
          )}

          {checklist
            .filter((item) => item.profile_position === "sundry")
            .map((item) => {
              const ItemNavIcon = getNavIcon(item)
              return (
                <Item>
                  <StyledLink to={item.path} activeClassName="active" info="1" onClick={toggleMenu}>
                    <MenuIconOuter>
                      {ItemNavIcon && <ItemNavIcon size="1x" color={theme.colors.body} />}
                      <span>{item.name}</span>
                    </MenuIconOuter>
                  </StyledLink>
                </Item>
              )
            })}

          {perksEnabled && (
            <Item>
              <StyledLink to="/perks" activeClassName="active" info="1" onClick={toggleMenu}>
                <MenuIconOuter>
                  <NavIcons.PerksIcon size="1x" color={theme.colors.body} />
                  <span>Perks</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}

          <Item>
            <StyledLink to="/settings" activeClassName="active" info="1" onClick={toggleMenu}>
              <MenuIconOuter>
                <NavIcons.SettingsIcon size="1x" color={theme.colors.body} />
                <span>Settings</span>
              </MenuIconOuter>
            </StyledLink>
          </Item>

          <Separator mobileOnly={true} shadow halfMargin />

          {userApplications.length > 1 && (
            <Item mobileOnly={true}>
              <StyledLink to="#" onClick={toggleMobileApplicationList} activeClassName="none" info="1">
                <MenuIconOuter>
                  <NavIcons.SwitchIcon size="1x" color={theme.colors.body} />
                  <span>Switch Application</span>
                </MenuIconOuter>
              </StyledLink>
            </Item>
          )}
          <Item mobileOnly={true}>
            <StyledLink to="#" onClick={logoutUser} activeClassName="none" info="1">
              <MenuIconOuter>
                <NavIcons.LogoutIcon size="1x" color={theme.colors.body} />
                <span>Log out</span>
              </MenuIconOuter>
            </StyledLink>
          </Item>
        </ItemList>
      )}
    </>
  )
}

Items.propTypes = {
  checklist: PropTypes.array.isRequired,
  profileStatus: PropTypes.string
}

export default withChecklist()(Items)

import backgroundImage from "../assets/images/backgrounds/campleaders.jpg"
import { shade } from "polished"

const campLeadersBlue = "#00ADE5"
const navy = "#004152"
const yellow = "#FAB700"
const green = "#99CC00"
const pink = "#ED2A53"
const lightGrey = "#F8F8F8"
const midLightGrey = "rgb(158, 158, 158)"
const midGrey = "rgb(102, 102, 102)"
const darkGrey = "#1F2B30"
const white = "#FFFFFF"
const alert = "#FF2230"
const inputFieldGrey = "#F6F6F6"
const inputFieldBlack = "#515151"
const inputFieldBorder = "#CCCCCC"
const inputFieldHint = "#999999"

const campleaders = {
  colors: {
    brand: campLeadersBlue,
    brand2: navy,
    body: white,
    background: white,
    primary: campLeadersBlue,
    primaryContrasted: shade(0.2, campLeadersBlue),
    primaryContrastedDarker: shade(0.3, campLeadersBlue),
    secondary: navy,
    success: green,
    warning: yellow,
    danger: pink,
    lightGrey: lightGrey,
    midLightGrey: midLightGrey,
    midGrey: midGrey,
    darkGrey: darkGrey,
    rgbaBrandLightened: "rgb(0 173 229 / 10%)",
    notifications: {
      meta: campLeadersBlue,
      badge: {
        background: alert,
        text: white
      }
    },
    profile: {
      headerBg: campLeadersBlue,
      avatarBackground: navy,
      buttons: {
        primary: {
          background: green,
          text: white
        },
        secondary: {
          background: white,
          text: white
        }
      },
      badge: {
        background: green,
        text: white
      }
    },
    logoutButton: {
      main: navy,
      text: white
    },
    statuses: {
      locked: darkGrey,
      incomplete: darkGrey,
      complete: green,
      started: yellow,
      rejected: pink
    },
    home: {
      headings: navy
    },
    banner: {
      background: "#02ADE51A",
      text: "#004152"
    },
    content: {
      background: lightGrey,
      text: navy,
      url: campLeadersBlue
    },
    contactInfo: {
      background: lightGrey,
      title: navy,
      text: campLeadersBlue
    },
    applicationProgress: {
      value: campLeadersBlue,
      bar: "#eeeeee",
      label: "#717171",
      stage: "#19434a"
    },
    tables: {
      headings: campLeadersBlue
    },
    buttons: {
      color: white,
      lightColor: white,
      darkColor: darkGrey,
      special: {
        main: navy,
        text: white
      }
    },
    incompletion: {
      lightenAmount: 0.3,
      background: yellow
    },
    fields: {
      text: inputFieldBlack,
      contentText: inputFieldBlack,
      contentHeading: "#666666",
      label: "#333333",
      border: inputFieldBorder,
      hint: inputFieldHint,
      fieldsetText: "#717171",
      backButton: "#CCCCCC",
      backButtonText: "#666666",
      invalid: {
        darkenAmount: 0.125
      },
      backgroundColor: inputFieldGrey,
      spacer: "#F2F2F2"
    },
    alerts: {
      info: "#004152",
      danger: "#FF2230",
      success: "#8FCB24"
    },
    conversations: {
      activeChat: "rgba(2, 173, 229, 0.1)"
    }
  },
  fonts: {
    body: {
      family: "Helvetica Neue",
      weight: "400"
    },
    headings: {
      family: "Helvetica Neue",
      weight: "700"
    }
  },
  images: {
    background: backgroundImage
  }
}

export default campleaders

import React, { useMemo, useState, useCallback, useEffect } from "react"
import { useCookies } from "react-cookie"
import Routes from "../../routes"

import { ThemeProvider } from "styled-components"
import { SeHanoverThemeProvider, SimpleAlert } from "@cleversteam/se-hanover-components"
import { BrowserRouter } from "react-router-dom"
import { BrandProvider } from "@context/BrandContext"
import { UserProvider } from "@context/UserContext"
import { getRequest } from "@helpers/apiHelpers"
import { getBrandFromId } from "@helpers/dataHelpers"
import { getEnvironment, getBrand, getTheme } from "@helpers/siteHelpers"
import { BugsnagBoundary } from "@helpers/bugsnag"
import TagManager from "react-gtm-module"
import { positions as alertPositions, Provider as AlertProvider } from "react-alert"
import { isEmpty } from "lodash"
import { googleTags, getGoogleTag } from "@helpers/googleTagHelpers"
import queryString from "query-string"
import chameleon from "@chamaeleonidae/chmln"
let isChameleonInitialized = false

const alertOptions = {
  timeout: 5000,
  position: alertPositions.BOTTOM_RIGHT
}

const theme = {
  ...getTheme(),
  breakpoints: {
    mobile: 0,
    tablet: 769,
    desktop: 1025,
    widescreen: 1216,
    fullhd: 1408,
    mahoosive: 1600,
    ludacris: 1800
  }
}

const App = () => {
  const [cookies] = useCookies(["authState"])
  const [isLoaded, setIsLoaded] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [menu, setMenu] = useState({
    checklist: [],
    liveChat: { show: false },
    progressInfo: {},
    userApplications: []
  })

  useEffect(() => {
    if (user && user.id) {
      setChameleon(user)
    }
  }, [user])

  const setChameleon = (user) => {
    if (!process.env.REACT_APP_CHAMELEON_API_KEY) return
    if (isChameleonInitialized) return

    chameleon.init(process.env.REACT_APP_CHAMELEON_API_KEY, {
      fastUrl: "https://fast.chameleon.io/",
      forceOverride: true
    })

    const params = {
      email: user.email || "",
      name: `${user.first_name || ""} ${user.last_name || ""}`,
      user_type_id: user.user_type?.id || "",
      user_type_name: user.user_type?.name || "",
      current_application_container_id: user.current_application_container_id || "",
      channel_id: user.current_application_container_channel_id || "",
      channel_name: user.current_application_container_channel_name || "",
      programme_id: user.current_application_container_programme || "",
      programme_name: user.current_application_container_programme_id || "",
      centre_id: user.current_application_container_centre?.id || "",
      centre_name: user.current_application_container_centre?.name || "",
      user_masquerading: user.current_user_masquerading || "",
      uid_hash: user.chameleon_encoded_uid || ""
    }

    chameleon.identify(user.id, params)
    isChameleonInitialized = true
  }

  const getMenuInfo = useCallback(() => {
    const { current_application_container_id, current_user_masquerading, access_token } = user
    getRequest(
      `/participants/application_containers/${current_application_container_id}/menu?&is_user_masquerading=${current_user_masquerading}`,
      access_token
    )
      .then((data) => {
        var checklist = data.checklist_items
        checklist.forEach((item) => {
          if (!!item.locked) item.status = "locked"
          else if (item.percentage === 0) item.status = "not_started"
          else if (item.incompletion_exists) item.status = "incomplete"
          else if (item.percentage === 100) item.status = "complete"
          else item.status = "started"
        })

        const applications = data.application_containers
        applications.forEach((application) => {
          application.brand = getBrandFromId(application.channel_id)
        })
        let currentApplication = applications.find(
          (application) => application.application_container_id === current_application_container_id
        )
        if (currentApplication === undefined) currentApplication = null

        setMenu({
          ...data,
          checklist: checklist,
          currentApplication: currentApplication,
          helpSupportUrl: data.help_support_url,
          communityUrl: data.community_url,
          liveChat: {
            show: false,
            integration_type: data.live_chat_integration_type,
            integration_key: data.live_chat_integration_key,
            integration_user_attributes: data.live_chat_integration_user_attributes
          },
          messageCount: data.message_count,
          messagingEnabled: data.messaging_enabled,
          notificationCount: data.notification_count,
          perksEnabled: data.perks_enabled,
          progressInfo: data.progress_info,
          showEmployers: data.show_employers,
          userApplications: applications
        })
      })
      .catch((error) => console.error(error))
  }, [user])

  useMemo(() => {
    const sessionAuthState = sessionStorage["authState"]
    let authState
    let isAuthenticated = false
    let user = {}

    const setTracker = (user = null) => {
      const environment = getEnvironment()
      if (environment !== "production") return

      // init global GTM (if we haven't already)
      if (window.dataLayer) return
      TagManager.initialize({ gtmId: googleTags.GLOBAL })

      // init per-locale GTM
      const params = queryString.parse(window.location.href)
      const locale = user?.locale || params.country_code
      const channel_name = getBrand().friendlyName
      const gtmId = getGoogleTag(channel_name, locale)
      if (!!gtmId) TagManager.initialize({ gtmId: gtmId })
    }

    if (cookies.authState !== undefined) {
      // We are logged in
      authState = cookies.authState
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (!isAuthenticated && sessionAuthState) {
      // We are masquerading
      authState = JSON.parse(sessionAuthState)
      isAuthenticated = authState.isAuthenticated
      user = authState.user
    }

    if (authState) {
      setIsAuthenticated(isAuthenticated)
      setUser(user)
      setTracker(user)
    } else {
      setTracker()
    }

    setIsLoaded(true)
  }, [setIsLoaded, cookies])

  useMemo(() => {
    if (!user || isEmpty(user) || !isAuthenticated) return

    getMenuInfo()
  }, [user, getMenuInfo, isAuthenticated])

  return (
    <>
      {!isLoaded && <></>}

      {isLoaded && (
        <BugsnagBoundary>
          <BrandProvider value={getBrand()}>
            <ThemeProvider theme={theme}>
              <SeHanoverThemeProvider theme={theme}>
                <AlertProvider template={SimpleAlert} {...alertOptions}>
                  <UserProvider value={{ user, menu, setUser, setMenu }}>
                    <BrowserRouter>
                      <Routes isAuthenticated={isAuthenticated} />
                    </BrowserRouter>
                  </UserProvider>
                </AlertProvider>
              </SeHanoverThemeProvider>
            </ThemeProvider>
          </BrandProvider>
        </BugsnagBoundary>
      )}
    </>
  )
}
export default App

import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import PublicRoute from "./public-route"
import PrivateRoute from "./private-route"
import Loadable from "react-loadable"
import Loading, { LoadingInProfileChecklist } from "@localComponents/loading"

const LoadingWithoutChecklist = { loading: Loading }
const LoadingWithChecklist = { loading: LoadingInProfileChecklist }

const SwitchApplication = Loadable({ loader: () => import("../pages/switch-application"), ...LoadingWithoutChecklist })
const Masquerade = Loadable({ loader: () => import("../pages/masquerade"), ...LoadingWithoutChecklist })
const SignInWithToken = Loadable({ loader: () => import("../pages/sign-in-with-token"), ...LoadingWithoutChecklist })
const Login = Loadable({ loader: () => import("../pages/login"), ...LoadingWithoutChecklist })
const Logout = Loadable({ loader: () => import("../pages/logout"), ...LoadingWithoutChecklist })
const ForgotPassword = Loadable({ loader: () => import("../pages/forgot-password"), ...LoadingWithoutChecklist })
const Register = Loadable({ loader: () => import("../pages/register"), ...LoadingWithoutChecklist })
const Notifications = Loadable({ loader: () => import("../pages/notifications"), ...LoadingWithoutChecklist })
const Home = Loadable({ loader: () => import("../pages/home"), ...LoadingWithoutChecklist })
const Welcome = Loadable({ loader: () => import("../pages/welcome"), ...LoadingWithoutChecklist })
const BasicInfo = Loadable({ loader: () => import("../pages/basic-info"), ...LoadingWithChecklist })
const Availability = Loadable({ loader: () => import("../pages/availability"), ...LoadingWithChecklist })
const Interviews = Loadable({ loader: () => import("../pages/interviews"), ...LoadingWithChecklist })
const NewInterview = Loadable({ loader: () => import("../pages/new-interview"), ...LoadingWithChecklist })
const Skills = Loadable({ loader: () => import("../pages/skills"), ...LoadingWithChecklist })
const SkillDetails = Loadable({ loader: () => import("../pages/skill-details"), ...LoadingWithChecklist })
const WorkExperience = Loadable({ loader: () => import("../pages/work-experience"), ...LoadingWithChecklist })
const NewWorkExperience = Loadable({ loader: () => import("../pages/new-work-experience"), ...LoadingWithChecklist })
const EditWorkExperience = Loadable({ loader: () => import("../pages/edit-work-experience"), ...LoadingWithChecklist })
const ApplicationForm = Loadable({ loader: () => import("../pages/application-form"), ...LoadingWithChecklist })
const SensitiveData = Loadable({ loader: () => import("../pages/sensitive-data"), ...LoadingWithChecklist })
const EmergencyContact = Loadable({ loader: () => import("../pages/emergency-contact"), ...LoadingWithChecklist })
const References = Loadable({ loader: () => import("../pages/references"), ...LoadingWithChecklist })
const NewReference = Loadable({ loader: () => import("../pages/new-reference"), ...LoadingWithChecklist })
const Preferences = Loadable({ loader: () => import("../pages/preferences"), ...LoadingWithChecklist })
const ProgramAgreement = Loadable({ loader: () => import("../pages/program-agreement"), ...LoadingWithoutChecklist })
const Events = Loadable({ loader: () => import("../pages/events"), ...LoadingWithoutChecklist })
const Event = Loadable({ loader: () => import("../pages/event"), ...LoadingWithoutChecklist })
const Employers = Loadable({ loader: () => import("../pages/employers"), ...LoadingWithoutChecklist })
const Courses = Loadable({ loader: () => import("../pages/courses"), ...LoadingWithoutChecklist })
const Course = Loadable({ loader: () => import("../pages/course"), ...LoadingWithoutChecklist })
const Payments = Loadable({ loader: () => import("../pages/payments"), ...LoadingWithoutChecklist })
const NewPayment = Loadable({ loader: () => import("../pages/new-payment"), ...LoadingWithoutChecklist })
const Paypal = Loadable({ loader: () => import("../pages/paypal"), ...LoadingWithoutChecklist })
const Stripe = Loadable({ loader: () => import("../pages/stripe"), ...LoadingWithoutChecklist })
const Referrals = Loadable({ loader: () => import("../pages/referrals"), ...LoadingWithoutChecklist })
const Settings = Loadable({ loader: () => import("../pages/settings"), ...LoadingWithoutChecklist })
const Document = Loadable({ loader: () => import("../pages/document"), ...LoadingWithoutChecklist })
const Documents = Loadable({ loader: () => import("../pages/documents"), ...LoadingWithoutChecklist })
const Insurance = Loadable({ loader: () => import("../pages/insurance"), ...LoadingWithoutChecklist })
const NewInsurance = Loadable({ loader: () => import("../pages/new-insurance"), ...LoadingWithoutChecklist })
const Visa = Loadable({ loader: () => import("../pages/visa"), ...LoadingWithoutChecklist })
const Flights = Loadable({ loader: () => import("../pages/flights"), ...LoadingWithoutChecklist })
const NewFlight = Loadable({ loader: () => import("../pages/new-flight"), ...LoadingWithoutChecklist })
const EditFlight = Loadable({ loader: () => import("../pages/edit-flight"), ...LoadingWithoutChecklist })
const Flight = Loadable({ loader: () => import("../pages/flight"), ...LoadingWithoutChecklist })
const TravelNotProvided = Loadable({ loader: () => import("../pages/travel-not-provided"), ...LoadingWithoutChecklist })
const WorkPermit = Loadable({ loader: () => import("../pages/work-permit"), ...LoadingWithoutChecklist })
const DatesAndPrice = Loadable({ loader: () => import("../pages/dates-and-price"), ...LoadingWithoutChecklist })
const Monitoring = Loadable({ loader: () => import("../pages/monitoring"), ...LoadingWithoutChecklist })
const MonitoringRequest = Loadable({ loader: () => import("../pages/monitoring-request"), ...LoadingWithoutChecklist })
const NewDeferral = Loadable({ loader: () => import("../pages/new-deferral"), ...LoadingWithoutChecklist })
const Deferral = Loadable({ loader: () => import("../pages/deferral"), ...LoadingWithoutChecklist })
const Offer = Loadable({ loader: () => import("../pages/offer"), ...LoadingWithoutChecklist })
const Conversations = Loadable({ loader: () => import("../pages/conversations"), ...LoadingWithoutChecklist })
const FlightOption = Loadable({ loader: () => import("../pages/flight-option"), ...LoadingWithoutChecklist })
const PoliceCheck = Loadable({ loader: () => import("../pages/police-check"), ...LoadingWithoutChecklist })
const NewPoliceCheck = Loadable({ loader: () => import("../pages/new-police-check"), ...LoadingWithoutChecklist })
const Perks = Loadable({ loader: () => import("../pages/perks"), ...LoadingWithoutChecklist })
const LinkedApplication = Loadable({ loader: () => import("../pages/linked-application"), ...LoadingWithoutChecklist })
const Community = Loadable({ loader: () => import("../pages/community"), ...LoadingWithoutChecklist })
const NotFound = Loadable({ loader: () => import("../pages/not-found"), ...LoadingWithoutChecklist })
const Forbidden = Loadable({ loader: () => import("../pages/forbidden"), ...LoadingWithoutChecklist })
const ServerError = Loadable({ loader: () => import("../pages/internal-server-error"), ...LoadingWithoutChecklist })
const ResetPassword = Loadable({ loader: () => import("../pages/reset-password"), ...LoadingWithoutChecklist })
const AcceptInterviewInvitation = Loadable({
  loader: () => import("../pages/accept-interview-invitation"),
  ...LoadingWithoutChecklist
})

const Routes = ({ isAuthenticated }) => (
  <Switch>
    {/* <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={() => window.location.reload()} />*/}
    <PublicRoute exact path="/switch-application/:token" component={SwitchApplication} />
    <PublicRoute exact path="/masquerade/:token" component={Masquerade} />
    <PublicRoute exact path="/sign-in-with-token/:token" component={SignInWithToken} />
    <PublicRoute exact path="/login" component={Login} isAuthenticated={isAuthenticated} />
    <PublicRoute exact path="/forgot-password" component={ForgotPassword} isAuthenticated={isAuthenticated} />
    <PublicRoute exact path="/apply" component={Register} isAuthenticated={isAuthenticated} />
    <PublicRoute exact path="/reset-password/:token" component={ResetPassword} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/" component={Home} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/welcome" component={Welcome} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/notifications" component={Notifications} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/availability" component={Availability} isAuthenticated={isAuthenticated} />
    <Redirect from="/us-payment" to="/payments?type=popp" />
    <PrivateRoute exact path="/payments" component={Payments} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/payments/new" component={NewPayment} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/payments/paypal" component={Paypal} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/payments/stripe" component={Stripe} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/interviews" component={Interviews} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/interviews/new" component={NewInterview} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/skills" component={Skills} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/skill-details" component={SkillDetails} isAuthenticated={isAuthenticated} />
    <PrivateRoute
      exact
      path="/work-experience/:id/edit"
      component={EditWorkExperience}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute exact path="/work-experience/new" component={NewWorkExperience} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/work-experience" component={WorkExperience} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/referrals" component={Referrals} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/sensitive-data" component={SensitiveData} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/flight-option" component={FlightOption} isAuthenticated={isAuthenticated} />
    <Redirect from="/add-referral-code" to="/referrals" />
    <Redirect from="/add-referal-code" to="/referrals" />
    <PrivateRoute exact path="/settings" component={Settings} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/program-agreement" component={ProgramAgreement} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/events" component={Events} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/events/:id" component={Event} isAuthenticated={isAuthenticated} />
    <Redirect from="/my-events" to="/events" />
    <PrivateRoute exact path="/employers" component={Employers} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/courses" component={Courses} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/courses/:id" component={Course} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/references/new" component={NewReference} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/references" component={References} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/basic-info/:step" component={BasicInfo} isAuthenticated={isAuthenticated} />
    <PrivateRoute path="/basic-info" component={BasicInfo} isAuthenticated={isAuthenticated} />
    <Redirect from="/basic-info/step-one" to="/basic-info" />
    <Redirect from="/basic-info/step-two" to="/basic-info/upload-photo" />
    <Redirect from="/basic-info/step-three" to="/basic-info/primary-contact-details" />
    <Redirect from="/basic-information" to="/basic-info" />
    <PrivateRoute exact path="/application-forms/:id" component={ApplicationForm} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/documents/:id" component={Document} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/documents" component={Documents} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/preferences" component={Preferences} isAuthenticated={isAuthenticated} />
    <Redirect from="/camp-preferences" to="/preferences" />
    <PrivateRoute exact path="/insurance/new" component={NewInsurance} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/insurance" component={Insurance} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/visa" component={Visa} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/flights" component={Flights} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/flights/new" component={NewFlight} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/flights/:id/edit" component={EditFlight} isAuthenticated={isAuthenticated} />
    <PrivateRoute
      exact
      path="/flights/travel-not-provided"
      component={TravelNotProvided}
      isAuthenticated={isAuthenticated}
    />
    <PrivateRoute exact path="/flights/:id" component={Flight} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/police-check" component={PoliceCheck} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/police-check/new" component={NewPoliceCheck} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/work-permit" component={WorkPermit} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/dates-and-price" component={DatesAndPrice} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/monitoring" component={Monitoring} isAuthenticated={isAuthenticated} />
    <Redirect from="/check-in" to="/monitoring" />
    <PrivateRoute exact path="/monitoring/:id" component={MonitoringRequest} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/deferral/new" component={NewDeferral} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/deferral/switch" component={NewDeferral} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/deferral" component={Deferral} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/offer/:id" component={Offer} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/conversations" component={Conversations} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/emergency-contact" component={EmergencyContact} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/perks" component={Perks} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/linked-application" component={LinkedApplication} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/community" component={Community} isAuthenticated={isAuthenticated} />
    <PublicRoute exact path="/accept-interview-invitation/:token" component={AcceptInterviewInvitation} />
    <PrivateRoute exact path="/403" component={Forbidden} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/404" component={NotFound} isAuthenticated={isAuthenticated} />
    <PrivateRoute exact path="/500" component={ServerError} isAuthenticated={isAuthenticated} />
    <Route exact path="/logout" component={Logout} />
    <Route component={NotFound} />
  </Switch>
)

export default Routes
